import {
  trackCoreWebVital,
  trackFormEnter,
  trackFormFieldError,
  trackFormLeave,
  trackFormNavigation,
  trackFormSubmitError,
  trackNextPageAfterError,
  trackPageView,
  trackSessionStart,
} from 'chimera/all/plugins/tracking/absmartly/events'

/**
 * Our event handling for ABSmartly.
 *
 * @param {object}  Vue
 */
export function bindEvents(Vue) {
  // Track form enter event.
  Vue.$eventBus.onFormEnterEvent((event) => {
    trackFormEnter(Vue, event)
  })

  // Track form leave event.
  Vue.$eventBus.onFormLeaveEvent((event) => {
    trackFormLeave(Vue, event)
  })

  // Track form navigation event.
  Vue.$eventBus.onFormNavigationEvent((event) => {
    trackFormNavigation(Vue, event)
  })

  // Track transitions to next pages.
  Vue.$eventBus.onTransitionEvent((event) => {
    trackNextPageAfterError(Vue, event)
  })

  // User input validation errors.
  Vue.$eventBus.onFormErrorEvent((event) => {
    trackFormFieldError(Vue, event)
  })

  // User Form validation errors.
  Vue.$eventBus.onFormSubmitErrorEvent((event) => {
    trackFormSubmitError(Vue, event)
  })

  // Core Web Vital Event.
  Vue.$eventBus.onCoreWebVitalEvent((event) => {
    trackCoreWebVital(Vue, event)
  })

  // Track session start event.
  Vue.$eventBus.onceOnPageViewEvent((event) => {
    trackSessionStart(Vue, event)
  })

  // Track page view event.
  Vue.$eventBus.onPageViewEvent((event) => {
    trackPageView(Vue, event)
  })
}
