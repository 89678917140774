module.exports = {
  global: {
    'contact-us': 'Neem contact op',
    back: 'Terug',
    continue: 'Ga verder',
    privacyPolicy: 'Privacy policy',
    privacyPolicyLink: '/privacy',
    cookiePolicy: 'Cookie policy',
    cookiePolicyLink: '/cookies',
    termsAndConditions: 'Algemene voorwaarden',
    termsAndConditionsLink: '/algemene-voorwaarden',
    imprint: '@todo',
    imprintLink: '@todo',
    companyLink: 'https://www.bambelo.com/',
    receiveUpToSixQuotes:
      'Ontvang tot 6 offertes van professionals in jouw regio en vergelijk.',
    requiredFields: 'Verplichte velden',
    other: 'Anders',
    yes: 'Ja',
    no: 'Nee',
  },

  validation: {
    required: 'Vergeet je deze niet?',
    invalid: 'Er lijkt iets fout te zijn gegaan.',
    blacklisted: 'Er lijkt iets fout te zijn gegaan.',
    invalidLength: 'Het aantal karakters lijkt niet te kloppen.',
  },

  field: {
    postal: {
      label: 'Postcode',
      placeholder: 'Postcode',
      validation: {
        invalid: 'Je postcode lijkt niet te kloppen.',
        required: 'Je bent je postcode vergeten in te vullen.',
        unknown: 'Deze postcode is bij ons niet bekend.',
        incorrectFormat:
          'Jouw postcode moet 4 cijfers en 2 letters bevatten (bijv. 1234AB)',
      },
    },

    comments: {
      label: 'Overige informatie',
      placeholder: 'Overige informatie',
      validation: {
        invalid: '@todo',
        required: 'Wil je echt niets kwijt aan onze experts?',
      },
    },

    'address-city': {
      label: 'Woonplaats',
      placeholder: 'Woonplaats',
      validation: {
        invalid: 'Je woonplaats lijkt niet te kloppen.',
        required: 'Je bent je woonplaats vergeten in te vullen.',
      },
    },

    'address-country-name': {
      label: 'Land',
      placeholder: 'Land',
      validation: {
        invalid: 'Je land lijkt niet te kloppen.',
        required: 'Je bent je land vergeten in te vullen.',
      },
    },

    'address-floor-level': {
      label: 'Etage',
      placeholder: 'Etage',
      validation: {
        invalid: 'Je etage lijkt niet te kloppen.',
        required: 'Je bent je etage vergeten in te vullen.',
      },
    },

    'address-number': {
      label: 'Huisnummer',
      placeholder: 'Huisnummer',
      validation: {
        invalid: 'Je huisnummer lijkt niet te kloppen.',
        required: 'Je bent je huisnummer vergeten in te vullen.',
      },
    },

    'address-property-type': {
      label: 'Type woning',
      placeholder: 'Type woning',
      validation: {
        invalid: 'Je type woning lijkt niet te kloppen.',
        required: 'Je bent je type woning vergeten in te vullen.',
      },
    },

    'address-street': {
      label: 'Adres',
      placeholder: 'Adres',
      validation: {
        invalid: 'Je straatnaam lijkt niet te kloppen.',
        required: 'Je bent je straatnaam vergeten in te vullen.',
      },
    },

    'company-name': {
      label: 'Bedrijfsnaam',
      placeholder: 'Bedrijfsnaam',
      validation: {
        invalid: 'Je bedrijfsnaam lijkt niet te kloppen.',
        required: 'Je bent je bedrijfsnaam vergeten in te vullen.',
      },
    },

    'dormer-width': {
      label: 'Breedte',
      placeholder: 'Breedte',
      validation: {
        invalid: 'Deze afmeting lijkt niet te kloppen.',
        required: 'Je bent de afmeting vergeten in te vullen.',
      },
    },

    email: {
      label: 'E-mail',
      placeholder: 'E-mail',
      validation: {
        invalid: 'Je e-mailadres lijkt niet te kloppen.',
        required: 'Je bent je e-mailadres vergeten in te vullen.',
      },
    },

    name: {
      label: 'Naam',
      placeholder: 'Naam',
      validation: {
        invalid: 'Je naam lijkt niet te kloppen.',
        required: 'Je bent je naam vergeten in te vullen.',
      },
    },

    phone: {
      label: 'Telefoonnummer',
      placeholder: 'Telefoonnummer',
      validation: {
        invalid: 'Je telefoonnummer lijkt niet te kloppen.',
        required: 'Je bent je telefoonnummer vergeten in te vullen.',
      },
    },

    'phone-tooltip': {
      trigger: 'Alleen voor eventuele vragen van experts.',
      title: 'Veiligheid en privacy',
      description:
        'Jouw gegevens worden veilig opgeslagen, uitsluitend gebruikt voor deze aanvraag, en alleen gedeeld met geselecteerde experts.',
    },

    message: {
      label: 'Je bericht',
      placeholder: 'Je bericht',
      validation: {
        invalid: 'Je bericht lijkt niet te kloppen.',
        required: 'Je bent je bericht vergeten in te vullen.',
      },
    },

    'contact-name': {
      label: 'Contactpersoon',
      placeholder: 'Contactpersoon',
      validation: {
        invalid: 'De naam van de contactpersoon lijkt niet te kloppen.',
        required:
          'Je bent de naam van de contactpersoon vergeten in te vullen.',
      },
    },

    'estimated-garden-area': {
      label: 'Geschatte tuinoppervlak',
      placeholder: 'Geschatte tuinoppervlak',
      validation: {
        invalid: 'Het geschatte tuinoppervlak lijkt niet te kloppen.',
        required: 'Je bent het geschatte tuinoppervlak vergeten in te vullen.',
      },
    },

    'move-preferred-date': {
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
      flexibleDate: 'Flexibel / in overleg',
      specificDate: 'Specifieke datum',
    },

    'period-of-construction': {
      options: {
        'before-1975': 'Voor 1975',
        'between-1975-and-1992': 'Tussen 1975 en 1992',
        'after-1992': 'Na 1992',
        'between-1975-and-1990': 'Tussen 1975 en 1990',
        'between-1990-and-2000': 'Tussen 1990 en 2000',
        'after-2000': 'Na 2000',
      },
    },

    'type-of-heating': {
      options: {
        'floor-heating': 'Vloerverwarming',
        'floor-heating-and-radiators': 'Vloerverwarming en radiatoren',
        radiators: 'Radiatoren',
      },
    },

    'type-of-heat-pump': {
      options: {
        'air-air-heat-pump': 'Lucht/lucht warmtepomp',
        'air-water-heat-pump': 'Lucht/water warmtepomp',
        'ground-water-heat-pump': 'Grond/water warmtepomp',
        'water-water-heat-pump': 'Water/water warmtepomp',
        'hybrid-heat-pump': 'Hybride warmtepomp',
        unknown: 'Weet ik niet, ik heb advies nodig',
      },
    },

    'type-of-insulation': {
      options: {
        'roof-insulation': 'Dakisolatie',
        'cavity-wall-insulation': 'Spouwmuur isolatie',
        'floor-insulation': 'Vloerisolatie',
        'double-glazing': 'Dubbelglas',
        'no-insulation': 'Er is geen isolatie',
        'facade-or-external-wall-insulation':
          'Gevelisolatie/Buitenmuur isolatie',
      },
    },

    'type-of-existing-radiator': {
      options: {
        'low-temperature-radiator': 'Lage temperatuur radiatoren',
        'regular-radiator': 'Standaard radiatoren',
        'low-temperature-radiator-description':
          '<strong>Lage temperatuur radiatoren</strong> werken met lagere watertemperaturen (35-55°C), ideaal voor warmtepompen.',
        'regular-radiator-description':
          '<strong>Standaard radiatoren</strong> werken met hogere watertemperaturen (+70°C), leveren snel warmte maar zijn minder efficiënt en kunnen temperatuurschommelingen veroorzaken.',
      },
    },

    'intends-to-replace-radiator': {
      options: {
        description:
          '<strong>Warmtepompen</strong> werken met een lage temperatuur voor verwarming. Als u de radiatoren niet wilt vervangen, zijn de opties qua type warmtepomp beperkt tot enkel hybride pompen. Dit leidt nog steeds tot een lagere gasrekening.',
      },
    },

    'application-type': 'Type aanvraag',
    'residential-building': 'Woonhuis',
    'business-premises': 'Bedrijfspand',
    'agricultural-enterprise': 'Agrarische onderneming',
    'large-business-requests': 'Groot zakelijke aanvragen',
    'type-of-assignment': 'Type opdracht',
    'house-battery': 'Thuisbatterij',
    'house-battery-and-solar-panels': 'Thuisbatterij en zonnepanelen',
    'estimated-annual-electricity-consumption':
      'Schatting jaarlijks elektriciteitsverbruik',
    'data-incompleted-error-message': 'Er ontbreken een paar gegevens',
  },

  privacyPolicy: {
    title: 'Privacyverklaring',
    headDescription: 'Privacybeleid voor website bezoekers',
    introduction:
      '{concept} hecht een grote waarde aan uw privacy. Daarom willen we open en transparant zijn over de verwerking van uw persoonsgegevens. In deze privacyverklaring vindt u daarom informatie over hoe uw persoonsgegevens worden verwerkt en beschermd. Dit privacybeleid is van toepassing op het gebruik van de website en de daarop ontsloten dienstverlening van {concept}',

    section1Title: 'Wie verwerken uw persoonsgegevens?',
    section1Body1:
      'Het Nederlands bedrijf Bambelo B.V. verwerkt de persoonsgegevens die u ons via een van onze websites verstrekt en is verantwoordelijk voor uw persoonsgegevens krachtens het toepasselijke recht inzake gegevensbescherming. Onze contactgegevens zijn:',
    companyAddressStreet: 'Prinsenkade 5B',
    companyZipcode: '4811 VB',
    companyAddressCity: 'Breda Nederland',
    companyChamberOfCommerce: 'Kamer van Koophandel nr.: 20111350',
    section1Body2:
      'Als u een van onze websites gebruikt voor het opvragen van offertes, geven wij uw gegevens door aan bij ons aangesloten partners die voldoen aan de door u opgegeven criteria. Zij verwerken uw gegevens conform hun eigen privacybeleid.  Op basis van deze informatie zullen deze bedrijven per e-mail of per telefoon contact met u zoeken om u te informeren over hun diensten en tarieven. Een enkele aanvraag via {concept} zal derhalve leiden tot gegevensopslag bij {concept} zelf én bij de bedrijven die aan uw aanvraag gekoppeld zijn. Op basis van wet- en regelgeving en een overeenkomst zoals die is gesloten tussen Bambelo B.V. en de organisaties waaraan Bambelo uw aanvraag verstrekt, dienen de organisaties die uw gegevens ontvangen, zich ook te houden aan de bepalingen van de met hen gesloten overeenkomst en aan de Algemene Verordening Gegevensbescherming (AVG). De contactgegevens van deze partijen vindt u in de offertes die u van hen ontvangt. Indien een bedrijf besluit om niet in te gaan op de offerte-aanvraag, zal dat bedrijf verplicht zijn om de ontvangen gegevens te vernietigen.\n',

    section2Title: 'Waar slaan we uw gegevens op?',
    section2Body1:
      'De gegevens die wij van u verzamelen worden in beginsel opgeslagen binnen de Europese Economische Ruimte (“EER”). Als gegevens door ons worden overgedragen aan en verwerkt in een land buiten de EER zal dit plaatsvinden in overeenstemming met de toepasselijke wetten, normaliter op basis van standaardcontractbepalingen en waar nodig aanvullende maatregelen.\n',

    section3Title:
      'Welke soorten persoonsgegevens verwerken we en op welke grondslag?',
    section3Body1: 'We verwerken de volgende categorieën persoonsgegevens:',
    section3List1Item1:
      'Contactgegevens, zoals naam, adres, e-mailadres en telefoonnummer',
    section3List1Item2:
      'Aanvullende gegevens relevant voor de aanvraag, onder andere:',
    section3List1Item2Sub1: 'Categorie',
    section3List1Item2Sub2: 'Type aanvraag',
    section3List1Item2Sub3: 'Prijsklasse',
    section3List1Item2Sub4: 'Type woning',
    section3List1Item2Sub5: 'Overige informatie, die u zelf aangeeft',
    section3Body2:
      'Wij verwerken bovengenoemde gegevens op basis van de volgende rechtsgronden:',
    section3List2Item1:
      'Als u offertes opvraagt via onze website: ter uitvoering van een met u gesloten overeenkomst, of op grond van uw toestemming',
    section3List2Item2:
      'Als u een vraag stelt (via onze website): op grond van uw toestemming',
    section3List2Item3:
      'In andere gevallen: voor de behartiging van gerechtvaardigde belangen die wij hebben in het kader van onze bedrijfsvoering, zoals relatiebeheer, verbeteren van onze dienstverlening, het beveiligen en toegankelijk houden van de website en het onderhouden van contact met onze relaties. Hierbij maken we te allen tijde een belangenafweging tussen deze belangen en uw privacy en verwerken we alleen de gegevens die noodzakelijk zijn om de vastgestelde doelen te bereiken.',

    section4Title: 'Hoe lang bewaren wij uw gegevens?',
    section4Body:
      'Wij bewaren uw gegevens gedurende een periode van maximaal 7 jaar. Dit doen wij enerzijds om aan onze wettelijke bewaartermijn te voldoen en anderzijds om trends waar te kunnen nemen en onze dienstverlening te verbeteren. Indien u aangeeft dat u niet langer van onze diensten gebruik wenst te maken c.q. de gegevens verwijderd wilt hebben zullen wij beoordelen of deze bewaartermijn verkort kan worden en welke gegevens wij kunnen verwijderen.',

    section5Title: 'Wie heeft toegang tot uw gegevens?',
    section5Body:
      'De door u opgegeven persoonsgegevens worden gebruikt om de door {concept} aangeboden dienstverlening naar behoren te laten werken. {concept} verschaft alleen de benodigde persoonsgegevens aan de aangesloten partners om u te voorzien van een passend aanbod op basis van uw aanvraag.',

    section6Title: 'Waarvoor gebruiken we uw persoonsgegevens?',
    section6Body:
      'We gebruiken uw gegevens allereerst ten behoeve van het opvragen van offertes voor het door u gezochte product of dienst. Voorts gebruiken wij deze om onze diensten, producten en systemen te evalueren, ontwikkelen en verbeteren. Dit omvat een analyse om onze diensten gebruikersvriendelijker te maken, zoals het wijzigen van de gebruikersinterface om de informatiestroom te vereenvoudigen of om functies te benadrukken die vaak gebruikt worden en om IT-systemen te verbeteren teneinde de veiligheid te vergroten. Daartoe zullen we uw gegevens niet op individueel niveau analyseren. Daarnaast gebruiken we de gegevens om nieuwe diensten te kunnen ontwikkelen en om ons productassortiment te verbeteren. Verstrekking (en verwerking) van deze gegevens is een noodzakelijke voorwaarde om uitvoering van de overeenkomst c.q. uitvoering van uw verzoek om offertes te ontvangen mogelijk te maken. Indien u de gegevens niet verstrekt kunnen wij u niet, of niet goed van dienst zijn.\n',

    section7Title: 'Wat zijn uw rechten?',
    section7Right1Title: 'Recht op toegang:',
    section7Right1:
      '{title} U hebt het recht om op elk moment informatie op te vragen over de persoonsgegevens die we over u bewaren.',
    section7Right2Title: 'Recht op portabiliteit:',
    section7Right2:
      '{title} Wanneer {concept} uw persoonsgegevens op geautomatiseerde wijze verwerkt op basis van uw toestemming of op basis van een overeenkomst, heeft u het recht om een kopie te krijgen van uw gegevens in een gestructureerd, algemeen gebruikt en machineleesbaar formaat, overgedragen aan u of aan een andere partij. Dit heeft alleen betrekking op de persoonsgegevens die u ons verstrekt hebt.',
    section7Right3Title: 'Recht op correctie:',
    section7Right3:
      '{title} U hebt het recht om te verzoeken om correctie van uw persoonsgegevens wanneer deze incorrect zijn, met inbegrip van het recht op completering van onvolledige persoonsgegevens.',
    section7Right4Title: 'Recht op vergetelheid:',
    section7Right4:
      '{title} U hebt het recht om ons te verzoeken de door {concept} verwerkte persoonsgegevens te verwijderen.',
    section7Right5Title:
      'Recht op beperking van de verwerking en bezwaar te maken tegen de verwerking:',
    section7Right5:
      '{title} U kunt vragen om de verwerking van gegevens (tijdelijk) te beperken, bijvoorbeeld om de juistheid van de gegevens te controleren. Ook heeft u het recht om bezwaar te maken tegen de verwerking van uw persoonsgegevens. Wanneer u bezwaar maakt tegen verwerking ten behoeve van direct marketing, worden de persoonsgegevens niet meer voor deze doeleinden verwerkt.',
    section7Right6Title: 'Recht om toestemming in te trekken:',
    section7Right6:
      '{title} Voor zover wij gegevens verwerken op basis van uw toestemming, heeft u te allen tijde het recht die toestemming in te trekken. Dit doet geen afbreuk aan de rechtmatigheid van de verwerking vóór de intrekking.',
    section7Body:
      'Voor alle bovengenoemde verzoeken geldt dat u binnen 1 maand een reactie op uw verzoek ontvangt. Afhankelijk van de complexiteit van de verzoeken en van het aantal verzoeken kan die termijn indien nodig met nog eens twee maanden worden verlengd, in dat geval informeren wij u hierover binnen een maand na ontvangst van uw verzoek.',

    section8Title: 'Hoe kunt u uw rechten uitoefenen?',
    section8Body:
      'We nemen gegevensbescherming heel serieus. Daarom hebben we een toegewijde klantenservice die uw vragen met betrekking tot uw bovengenoemde rechten behandelt en waar u een klacht kunt indienen. U kunt onze medewerkers altijd bereiken via {email}.',

    section9Title: 'Medewerking aan fiscaal en strafrechtelijk onderzoek',
    section9Body:
      'In voorkomende gevallen kunnen wij op grond van een wettelijke verplichting worden gehouden tot het delen van uw gegevens in verband met fiscaal en strafrechtelijk onderzoek van overheidswege. In een dergelijk geval zijn wij gedwongen uw gegevens te delen, maar wij zullen ons binnen de mogelijkheden die de wet biedt daartegen verzetten.',

    section10Title: 'Functionaris gegevensbescherming',
    section10Body:
      'We hebben een functionaris voor gegevensbescherming (FG) benoemd, om ervoor te zorgen dat we uw persoonsgegevens continu op een open, accurate en legale manier verwerken. U kunt contact opnemen met onze FG via {email} met FG als onderwerp.',

    section11Title:
      'Het recht om een klacht in te dienen bij een toezichthoudende autoriteit',
    section11Body:
      'Indien u van mening bent dat {concept} uw persoonsgegevens niet correct verwerkt, kunt u contact met ons opnemen. U heeft ook het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens tegen deze verwerkingen van persoonsgegevens. U kunt hiervoor contact opnemen met de Autoriteit Persoonsgegevens via deze {link}',
    section11BodyLinkText: 'link.',
    section11BodyLink:
      'https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap',

    section12Title: 'Updates van onze privacyverklaring',
    section12Body:
      'Het kan voorkomen dat onze privacyverklaring moet worden bijgewerkt. De laatste versie van de privacyverklaring is altijd beschikbaar op onze website. We maken alle materiële wijzigingen aan de privacyverklaring bekend, bijvoorbeeld met betrekking tot het doel van het gebruik van uw persoonsgegevens, de identiteit van de Controller (beheerder) of uw rechten.',

    version: 'Versie 4.1 – 23 februari 2022',
  },

  cookiePolicy: {
    headTitle: 'Cookiebeleid',
    title: 'Cookies',
    whatIsCookies: {
      title: 'Wat zijn cookies?',
      body: 'Cookies zijn kleine stukjes informatie die door uw browser worden opgeslagen op uw computer bij het bezoeken van onze website en dat bij volgende bezoeken kan worden opgeroepen. Wij maken op deze website gebruik van cookies.',
    },
    whatIsCookieFunction: {
      title: 'Wat is de functie van deze cookies?',
      firstParagraph:
        'Wij gebruiken cookies om ervoor te zorgen dat u bij een bezoek aan onze website niet herhaaldelijk dezelfde informatie ontvangt of moet invoeren. Er bestaan verschillende soorten cookies. Als bijlage vindt u een overzicht met alle gebruikte cookies die worden weergegeven, wie de cookies plaatst, de naam van de cookie, het doel van het plaatsen van de cookie, de termijn dat cookie wordt bewaard en de gevolgen van het niet accepteren van de cookie.',
      secondParagraph:
        'U kunt het opslaan van cookies op uw computer uitzetten via uw browser. U kunt ook op de hoogte worden gesteld wanneer u een cookie ontvangt. Instructies met betrekking tot het aanpassen van de instellingen van de browser vindt u onder ‘help’ in de toolbar van de meeste browsers. Hoewel u als bezoeker van de site de site nog steeds kunt bezoeken is het echter mogelijk dat als cookies worden geweigerd, bepaalde diensten of elementen van de site niet optimaal functioneren. Het uitzetten van cookies beperkt het gebruik van onze site en diensten.',
      thirdParagraph:
        'Sommige tracking cookies worden geplaatst door derden die onder meer via onze website advertenties vertonen aan u. Deze cookies kunt u centraal verwijderen via {link} zodat ze niet bij een website van een derde teruggeplaatst worden.',
      url: 'https://www.youronlinechoices.com/nl/uw-advertentie-voorkeuren',
    },
    whoIAm: {
      title: 'Weten cookies wie ik ben?',
      body: 'Nee. Uw naam, adres, leeftijd en andere persoonlijk gegevens weet een cookie niet. Ze onthouden alléén uw voorkeuren en uw interesse(s) op basis van uw surfgedrag.',
    },
    listTitle: 'Lijst met cookies',
    adjustPreferences: 'Pas cookievoorkeuren aan',
    version: 'Versie',
  },

  termsAndConditions: {
    title: 'Algemene voorwaarden',
    headDescription: 'Algemene voorwaarden',
  },
}
