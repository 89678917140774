import * as accountancy from 'chimera/accountancy/service'
import * as airConditioning from 'chimera/airConditioning/service'
import { EventBus } from 'chimera/all/plugins/eventbus'
import * as boiler from 'chimera/boiler/service'
import * as dormer from 'chimera/dormer/service'
import * as drivingSchool from 'chimera/drivingSchool/service'
import * as gardener from 'chimera/gardener/service'
import * as heatPump from 'chimera/heatPump/service'
import * as homeBatteries from 'chimera/homeBatteries/service'
import * as insulation from 'chimera/insulation/service'
import * as moving from 'chimera/moving/service'
import * as notary from 'chimera/officeOfNotary/service'
import * as painting from 'chimera/painting/service'
import * as realEstate from 'chimera/realEstateAgency/service'
import * as solarPanels from 'chimera/solarPanels/service'
import * as sunscreens from 'chimera/sunscreens/service'
import * as windowFrames from 'chimera/windowFrames/service'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    id: '',
    identifier: '',
  }
}

/**
 * Grab all given service objects and merge & map them into one object.
 *
 * @example {
 *  'd26f935b-87d4-447e-a783-be395cc83675': {
 *    id: 'd26f935b-87d4-447e-a783-be395cc83675',
 *    identifier: 'branche-category-consumer'
 *  }
 * }
 *
 * @param {object[]} serviceObjects
 * @returns {object}
 */
function mapServices(...serviceObjects) {
  const mergedServices = Object.assign(...serviceObjects)

  const services = {}
  Object.entries(mergedServices).forEach(([key, value]) => {
    services[value.id] = value
  })
  return services
}

/**
 * Total list of services with id: description mapping. Internally used
 * to gather related data of an received uuid.
 *
 * Note that this solution results in these ids and descriptions being compiled into
 * every single static build, even when the services aren't required for a concept.
 * TODO: https://yonego.atlassian.net/browse/PD-1733
 */
const services = mapServices(
  airConditioning,
  accountancy,
  realEstate,
  notary,
  gardener,
  dormer,
  solarPanels,
  painting,
  insulation,
  windowFrames,
  drivingSchool,
  moving,
  heatPump,
  sunscreens,
  boiler,
  homeBatteries,
)

/**
 * @param {string} id
 * @param {object} services
 *
 * @returns {object}
 */
function getServiceForId(id, services) {
  const service = services[id]
  if (!service) {
    throw new Error(`Service for id ${id} could not be found`)
  }

  if ('identifier' in service && service.identifier.length === 0) {
    throw new Error(`Identifier for service with id ${id} could not be found`)
  }

  return service
}

const state = getDefaultState

const mutations = {
  /**
   * Set service
   *
   * @param {object} state
   * @param {object} service
   * @param {string} service.id
   * @param {string} service.identifier
   */
  setService(state, { id, identifier }) {
    state.id = id
    state.identifier = identifier
  },

  /**
   * Reset state
   *
   * @param {object} state
   * @param {object} newState
   */
  reset(state, newState = getDefaultState()) {
    Object.keys(state).forEach((key) => Vue.delete(state, key))

    Object.assign(state, newState)
  },
}

const actions = {
  /**
   * Add data to store
   *
   * @param {object} context
   * @param {Function} context.commit
   * @param {string} id
   */
  set({ commit }, id) {
    const service = exports.getServiceForId(id, services)

    // Put in store.
    commit('setService', service)

    // Update service in context
    commit('context/set', { service }, { root: true })

    // Emit service selection event
    EventBus.emitServiceSelectionEvent(service)
  },
}

const exports = {
  state,
  actions,
  mutations,
  getServiceForId,
}

export default exports
